import React, { useState, useEffect } from "react";
import { Table, Card, Space, Upload } from "lib";
import {
  Button,
  Form,
  Input,
  message,
  Modal,
  TablePaginationConfig,
} from "antd";
import { Layout } from "layout";
import { QuestionChoiceListBriefDto, QuestionChoiceListsClient } from "@api";
import { showError, Download } from "@action";
import { FormattedMessage,useIntl } from "react-intl";
import QuestionMenu from "@components/Admin/Question/QuestionMenu";
import { PlusOutlined } from "@ant-design/icons";
import { RcFile } from "antd/lib/upload";
import { ACTION, SECTION, SubpageAuth } from "@services/auth";
import { navigate } from "gatsby";

const defaultOrderBy = "id";
const defaultSortOrder = "descend";
const defaultPagination: TablePaginationConfig = {
  current: 1,
  pageSize: 10,
  position: ["topRight", "bottomRight"],
};

function capitalizeFirstLetter(str: string) {
  return str.charAt(0).toUpperCase() + str.slice(1);
}

const Page = () => {
  const auth = new SubpageAuth(
    SECTION.Admin,
    "Questions",
    "QuestionChoiceLists"
  );
  if (auth.cannot(ACTION.Access)) {
    navigate("/403/");
    return <></>;
  }

  const [modal, modalContextHolder] = Modal.useModal();
  const [messageApi, messageContextHolder] = message.useMessage();
  const [form] = Form.useForm();

  const choiceListClient = new QuestionChoiceListsClient();

  const [state, setState] = useState({
    items: [] as QuestionChoiceListBriefDto[],
    pagination: defaultPagination,
    loading: false,
  });
  const { formatMessage } = useIntl();
  const StaticKeywords = formatMessage({ id: "StaticKeywords" });
  const [keywords, setKeywords] = useState(
    undefined as string | undefined | null
  );
  const [createModalVisible, setCreateModalVisible] = useState(false);

  async function fetchItems(
    params: any = { pagination: state.pagination },
    _keywords: string | undefined | null = undefined
  ) {
    if (auth.cannot(ACTION.List, "QuestionChoiceLists")) return;

    setState({
      ...state,
      loading: true,
    });
    // console.log(_keywords, keywords, _keywords ?? keywords);
    try {
      const res = await choiceListClient.getQuestionChoiceLists(
        _keywords ?? keywords,
        params.pagination.current,
        params.pagination.pageSize,
        capitalizeFirstLetter(params.sortField ?? defaultOrderBy),
        (params.sortOrder ?? defaultSortOrder) == "ascend"
      );
      setState({
        ...state,
        items:
          (res.items as QuestionChoiceListBriefDto[]) ||
          ([] as QuestionChoiceListBriefDto[]),
        pagination: {
          ...params.pagination,
          total: res.totalCount,
        },
        loading: false,
      });
    } catch (err) {
      setState({
        ...state,
        loading: false,
      });
      showError(err);
    }
  }

  async function handleTableChange(
    pagination: TablePaginationConfig,
    filters: any,
    sorter: any
  ) {
    await fetchItems({
      sortField: sorter.field,
      sortOrder: sorter.order,
      pagination,
      ...filters,
    });
  }

  async function handleDownloadChoiceLists() {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticPrepareDownloadMessage" />,
      0
    );
    try {
      const res = await choiceListClient.export();
      return Download(res);
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleUploadChoiceLists(f: RcFile) {
    const hide = messageApi.loading(
      <FormattedMessage id="StaticUploadingMessage" />,
      0
    );
    try {
      await choiceListClient.upload({ data: f, fileName: f.name });
      fetchItems();
      messageApi.success(<FormattedMessage id="StaticUploadSuccessMessage" />);
      return f.name;
    } catch (err) {
      showError(err);
    } finally {
      hide();
    }
  }

  async function handleAddChoiceList() {
    try {
      const values = await form.validateFields();
      if (values) {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticCreatingMessage" />
        );
        try {
          await choiceListClient.create(values);
          messageApi.success(
            <FormattedMessage id="StaticCreateSuccessMessage" />
          );
          setCreateModalVisible(false);
          form.resetFields();
          await fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      }
    } catch (err) {}
  }

  function handleCancelAddChoiceList() {
    setCreateModalVisible(false);
    form.resetFields();
  }

  function handleDeleteChoiceList(id: number) {
    modal.confirm({
      title: <FormattedMessage id="StaticConfirmDeleteMessage" />,
      okText: <FormattedMessage id="StaticYesLabel" />,
      cancelText: <FormattedMessage id="StaticNoLabel" />,
      async onOk() {
        const hide = messageApi.loading(
          <FormattedMessage id="StaticDeletingMessage" />
        );
        try {
          await choiceListClient.delete(id);
          messageApi.success(
            <FormattedMessage id="StaticDeleteSuccessMessage" />
          );
          fetchItems();
        } catch (err) {
          showError(err);
        } finally {
          hide();
        }
      },
    });
  }

  async function handleKeywordsSearch(values: any) {
    setKeywords(values.keywords);
    await fetchItems(undefined, values.keywords);
  }

  const columns = [
    {
      // title: "ID",
      title: <FormattedMessage id="QuestionChoiceListTableHeaderId" />,
      dataIndex: "id",
      key: "id",
      sorter: true,
      defaultSortOrder,
    },
    {
      // title: "Question Choice List",
      title: <FormattedMessage id="QuestionChoiceListTableHeaderQuestionChoiceList" />,
      dataIndex: "name",
      key: "name",
      sorter: true,
    },
    {
      // title: "Remarks",
      title: <FormattedMessage id="QuestionChoiceListTableHeaderRemarks" />,
      dataIndex: "remarks",
      key: "remarks",
      sorter: true,
    },
    // {
    //   title: "Time added",
    //   dataIndex: "created",
    //   key: "created",
    //   sorter: true,
    //   defaultSortOrder,
    //   render: (d: Date) => <>{d?.toLocaleString()}</>,
    // },
    {
      // title: "Action",
      title: <FormattedMessage id="QuestionChoiceListTableHeaderAction" />,
      key: "action",
      render: (text, record: QuestionChoiceListBriefDto) =>
        record.id && auth.can(ACTION.Delete, "QuestionChoiceLists") ? (
          <Button
            danger
            ghost
            onClick={() => handleDeleteChoiceList(record.id as number)}
          >
            <FormattedMessage id="StaticDeleteButton" />
          </Button>
        ) : null,
    },
  ];

  useEffect(() => {
    fetchItems();
  }, []);

  return (
    <Layout title="QuestionChoiceListNavigationTitle">
      <Card>
        <Space direction="vertical" size="middle">
          <QuestionMenu />
          <Space justify="space-between">
            <Space justify="flex-start">
              <Form
                form={form}
                name="horizontal_login"
                layout="inline"
                onFinish={handleKeywordsSearch}
              >
                <Form.Item name="keywords">
                  <Input placeholder={StaticKeywords} />
                </Form.Item>
                <Form.Item shouldUpdate>
                  {() => (
                    <Button type="primary" htmlType="submit">
                      <FormattedMessage id="StaticSearchLabel" />
                    </Button>
                  )}
                </Form.Item>
              </Form>
            </Space>
            <Space wrap>
              {auth.can(ACTION.Create, "QuestionChoiceLists") ? (
                <Button
                  type="primary"
                  onClick={() => {
                    setCreateModalVisible(true);
                  }}
                >
                  <Space>
                    <PlusOutlined />
                    <FormattedMessage id="QuestionChoiceListContentAddButton" />
                  </Space>
                </Button>
              ) : null}
              {auth.can(ACTION.Download, "QuestionChoiceLists") ? (
                <Button type="primary" onClick={handleDownloadChoiceLists}>
                  <FormattedMessage id="QuestionChoiceListContentDownloadButton" />
                </Button>
              ) : null}
              {auth.can(ACTION.Upload, "QuestionChoiceLists") ? (
                <Upload action={handleUploadChoiceLists}>
                  <Button type="primary">
                    <FormattedMessage id="QuestionChoiceListContentUploadButton" />
                  </Button>
                </Upload>
              ) : null}
            </Space>
          </Space>
          <Table
            bordered
            dataSource={[...state.items]}
            columns={columns}
            rowKey={(r) => r.id}
            pagination={state.pagination}
            loading={state.loading}
            onChange={handleTableChange}
          />
        </Space>
      </Card>
      <Modal
        visible={createModalVisible}
        title={<FormattedMessage id="QuestionChoiceListModalAddTitle" />}
        okText={<FormattedMessage id="StaticCreateButton" />}
        cancelText={<FormattedMessage id="StaticCancelButton" />}
        onOk={handleAddChoiceList}
        onCancel={handleCancelAddChoiceList}
        destroyOnClose={true}
      >
        <Form layout="horizontal" form={form}>
          <Form.Item
            name="name"
            label={<FormattedMessage id="QuestionChoiceListStaticNameLabel" />}
            rules={[
              {
                required: true,
                message: (
                  <FormattedMessage id="QuestionChoiceListStaticMissingNameRule" />
                ),
              },
            ]}
            initialValue=""
          >
            <Input />
          </Form.Item>
          <Form.Item
            name="remarks"
            label={
              <FormattedMessage id="QuestionChoiceListStaticRemarkLabel" />
            }
            initialValue=""
          >
            <Input />
          </Form.Item>
        </Form>
      </Modal>
      {modalContextHolder}
      {messageContextHolder}
    </Layout>
  );
};

export default Page;
